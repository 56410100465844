import { defineStore } from "pinia";

export const userStore = defineStore("userStore", {
    state: () => ({
        pinRequired: false,
        start: false,
        processStarted: false,
        data: {
            product: {},
            weighings: [],
            mhd: null,
            durchgefuehrtVon: "",
            planMenge: 0,
            gewogen: 0,
            istMenge: 0,
            zuWiegen: 0,
            mittelwert: 0,
        },
        tuValue: 0,
        currentSaldo: 0,
        currentState: "",
        toleranzBereich: 0,
        formData:{
            chargeName:"",
            chargeData:[]
        },
        credentials : {
            login: "itqan_rabbit_fe_01",
            passcode: "",
            passcodeRB: "Foods.1453",
          },
    }),

    getters: {
        getProcessStarted(state) {
            return state.processStarted;
        },
        getCharge(state) {
            return state.charge;
        },
        getWeighings(state) {
            return state.weighings;
        },
    },
    actions: {
        async setProcessStarted(processStarted) {
            this.processStarted = processStarted;
        },
        async setCharge(charge) {
            this.charge = charge;
        },
        async setWeighings(weighings) {
            this.weighings = weighings;
        },
    },
    persist: true,
});
